<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'
import config from './Config.js'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {   
      title:config.title,
      dataurl:config.dataulr,
      baseroute:config.baseroute,
      fields:config.fields,
    }
  },
}
</script>